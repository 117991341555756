.contacts-page {
    min-height: 100vh;
    width: auto;
    background: #B9FFF8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contacts-page .contact-page-title {
    color: #000000;
    margin-bottom: 24px;
    font-size: 18px;
    text-decoration: underline;
}

.contacts-container {
    width: 80%;
    background: #6FEDD6;
    padding: 20px 60px 40px 40px;
    margin-bottom: 80px;
}

.contacts-container .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contacts-container .content .left-side {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 15px;
}

.content .left-side::before {
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
}

.content .left-side .details {
    margin: 14px;
    text-align: center;

}

.content .left-side .details i {
    font-size: 30px;
    color: #231955;
    margin-bottom: 10px;
}

.content .left-side .details .topic {
    font-size: 18px;
    font-weight: 1000;
}

.content .left-side .details .text-one,
.content .left-side .details .text-two {
    font-size: 14px;
    color: #000000;
}

.contacts-container .content .right-side {
    width: 75%;
    margin-top: 12px;
    margin-left: 75px;
}

.content .right-side .topic-text {
    font-size: 23px;
    font-weight: 600;
    color: #231955;
}

.right-side .input-box {
    height: 50px;
    width: 100%;
    margin: 12px 0;
}

.right-side .input-box input,
.right-side .input-box textarea {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 16px;
    background: #f0f1f8;
    padding: 0 15px;
}

.right-side .message-box {
    min-height: 110px;
    margin-top: 6px;
}

.right-side .button {
    display: inline-block;
    margin-top: 12px;
}

.right-side .button input[type="button"] {
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    background-color: #231955;
    cursor: pointer;
    transition: all 0.3s ease;
}

.right-side .button input[type="button"]:hover {
    background: #5029bc;
}

@media (max-width: 950px) {
    .contacts-container{
      width: 90%;
      padding: 30px 40px 40px 35px ;
    }
    .contacts-container .content .right-side{
     width: 75%;
     margin-left: 55px;
  }
}
  @media (max-width: 820px) {
    .contacts-container{
      margin: 40px 0;
      height: 100%;
    }
    .contacts-container .content{
      flex-direction: column-reverse;
    }
   .contacts-container .content .left-side{
     width: 100%;
     flex-direction: row;
     margin-top: 40px;
     justify-content: center;
     flex-wrap: wrap;
   }
   .contacts-container .content .left-side::before{
     display: none;
   }
   .contacts-container .content .right-side{
     width: 100%;
     margin-left: 0;
   }
}
  
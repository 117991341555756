.msg__card-container {
    width: 550px;
    height: 550px;
    overflow: hidden;
    transition: 0.3s;
    animation: ease-in;
    border: 2px solid #000000;
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: transparent;
    color: #000000;
}

.msg__image-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 16px;
    width: 50%;
    text-align: center;
}

.msg__image-container img {
    overflow: hidden;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #000000;
}

.msg__card-content {
    text-align: center;
    margin: 1rem;
    margin-top: 0.5rem;
}

.msg__card-title h2 {
    font-size: 24px;
    margin: 0;
    padding: 0;
}

.msg__card-position h3 {
    font-size: 18px;
    margin: 0;
    padding: 0;
    color: #ff4a4a;
}

.msg__card-description p {
    font-size: 14px;
}

.msg__card-title {
    margin-bottom: 0.5rem;
}

.msg__card-position {
    margin-bottom: 0.5rem;
}

.msg__card-description {
    text-align: center;
    font-style: normal;
}

.about__message {
    display: flex;
    justify-content: center;
    align-items: center;
}

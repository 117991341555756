.footer {
    padding-top: 32px;
    padding-bottom: 10px;
    background-color: #FF4A4A;
    font-weight: 400;
  }
  
  .footer .social {
    text-align: center;
    padding-bottom: 32px;
    color: #fff;
  }
  
  .footer .social a {
    font-size: 24px;
    color: #fff;
    border: 1px solid #fff;
    padding: 4px;
    width: 40px;
    height: 40px;
    line-height: 38px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 8px;
    opacity: 0.75;
  }
  
  .footer .social a:hover {
    opacity: 0.9;
    border: 1px solid #000000;
    color: #000000;
  }
  
  .footer ul {
    margin-top: 0;
    padding: 0;
    list-style: none;
    line-height: 1.6;
    margin-bottom: 0;
    text-align: center;
    color: #fff;
  }
  
  .footer ul li a {
    color: inherit;
    text-decoration: none;
    opacity: 0.8;
  }
  
  .footer ul li {
    display: inline-block;
    padding: 0 15px;
  }
  
  .footer ul li a:hover {
    opacity: 1;
  }
  
  .footer .copyright {
    padding: 0;
    margin-top: 32px;
    text-align: center;
    font-size: 13px;
    color: #aaa;
  }
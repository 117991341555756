.services__section {
    padding: 25px 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    background-color: #B9FFF8;
  }
  
  .services__container-card {
    background: transparent;
    width: 300px;
    height: 150px;
    text-decoration: none;
    margin-left: 15px;
    margin-right: 20px;
  }
  
  .services__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .services__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  
  .services__heading {
    color: #000000;
    margin-bottom: 24px;
    font-size: 36px;
    text-decoration: underline;
  }
  
  .services__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;
    align-items: center;
    color: #fff;
  }
  
  .services__container-cardInfo h3 {
    margin-bottom: 5px;
    font-size: 24px;
    color: #000000;
  }
  
  .services__container-cardInfo h4 {
    font-size: 50px;
    color: #2B7A0B;
  }
  
  .services__container-cardInfo p {
    font-size: 14px;
    margin-bottom: 24px;
  }
  
  .services__container-features {
    margin: 16px 0 32px;
    list-style: none;
  }
  
  .services__container-features li {
    margin-bottom: 10px;
  }
  
  .services__container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon {
    margin: 24px 0;
  }
  
  @media screen and (max-width: 960px) {
    .services__section {
      padding-top: 12px;
      padding-bottom: 8px;
    }

    .services__heading {
      font-size: 36px;
    }

    .services__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .services__container-card {
      width: 250px;
      height: 150px;
      margin-bottom: 16px;
    }
  
    .services__wrapper {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .services__container-card:hover {
      transform: none;
    }

    .icon {
      display: none;
    }

    .services__container-cardInfo {
      text-align: center;
    }

    .services__container-cardInfo h4 {
      font-size: 36px;
    }

    .services__container-cardInfo h3 {
      font-size: 18px;
    }
  }
  